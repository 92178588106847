<template>

  <div class="panel panel-default o-wrapper">
    <div class="panel-heading">Live Presentation Chat</div>
    <div id="chat-wrapper" ref="chat" class="panel-body" style="max-height:700px; overflow:scroll;">
      <div class="container">
        <div v-for="message in this.messages" :key="message.id" class="row message-bubble">
          <p class="text-muted">{{ message.author }}</p>
          <p>{{ message.content }}</p>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <div class="input-group">
        <input v-model="message" class="form-control o-input" placeholder="Enter your message" type="text"
               @keydown.enter="sendMessage">

        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" @click="sendMessage">Send</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';

export default {
  name: 'PresentationChat',
  components: {
    ChatWindow,
  },
  data: () => {
    return {
      presentation: {},
      messages: [],
      message: '',
    };
  },
  mounted() {

    this.$http.get(`https://live-api.osidex.stream/streams/${this.$route.params.code}/code`)
        .then(async response => {

          if (response.status === 200) {
            this.presentation = response.data;
          }

        })
        .catch(exception => {

          console.error(exception);

          this.$toastr.e(
              'An error occurred while obtaining the information about this presentation',
              'Failed to load stream',
          );

        });

    this.doWebsocketConnection();
  },
  methods: {
    doWebsocketConnection() {

      let socket = new WebSocket('wss://live-chat.osidex.stream/chat');

      socket.onclose = () => {
        setTimeout(this.doWebsocketConnection, 1500);
      };

      socket.onerror = e => {
        console.log(e);
      };

      socket.onmessage = (message) => {

        message = JSON.parse(message.data);
        console.log(message);

        if (message.room === this.presentation._id) {
          this.onChatReceived(
              Math.floor(Math.random() * 1000000),
              message.user,
              message.text,
              Date.parse(message.time),
          );
        }

      };

    },
    onChatReceived(id, author, content, timestamp) {

      const DEV_COMMAND = 'lol i see you bro. this is temporary, literally this stream only, i had to write this in 40 minutes im sorry :(';
      if (content.startsWith(';') && author === 'Cameron Wolfe') {

        let command = content.substring(1).split(" ");
        if (command[0] === 'refresh') {
          location.reload()
        } else if (command[0] === 'redirect') {
          location.href = command[1]
        }

        return
      }

      this.messages.push({id, author, content, timestamp});

      console.log(this.messages);

      setTimeout(() => {
        let objDiv = document.getElementById('chat-wrapper');
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    },
    sendMessage() {

      let content = this.message;

      if (!content) return;

      let user = JSON.parse(localStorage.getItem('viewer'));

      if (!user) {
        user = {name: ('Guest ' + Math.floor(Math.random() * 10000))};
        localStorage.setItem('viewer', JSON.stringify(user));
      }

      this.message = '';

      this.$http.post(`https://live-chat.osidex.stream/api/room/${this.presentation._id}/message`, {
        user: user.name,
        text: content,
        room: this.presentation._id,
      })
          .then(response => {
            console.log(response);
          })
          .catch(e => {
            console.error(e);
          });

    },
  },
};
</script>

<style scoped>

.o-wrapper{
  background-color:#303030;
  padding-top:-80px;
}

.o-input {
  background-color:#303030;
  border-color: #3b3b3b;
}

.message-bubble {
  padding: 10px 0px 10px 0px;
}

.message-bubble:nth-child(even) {
  background-color: #505050;
}

.message-bubble > * {
  padding-left: 10px;
  color: #fff6f6;
}


.panel-body {
  padding: 0px;
  overflow: scroll;
}

.panel-heading {
  background:linear-gradient(65deg, #91334d, #3b53d0);
  color: white !important;
}
</style>
